import React from 'react'
import SEO from 'components/seo'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import '../components/header'
import { formatHTMLEntity, isNotEmptyArray, goBack } from 'utils'

import * as MemberStyles from '../styles/member.module.css'
import * as ClientStyles from '../styles/clients.module.css'

export const query = graphql`
  query($id: ID!) {
    wordPress {
      client(id: $id) {
        clientsFieldGroup {
          clientName
          clientIndustry
          clientWebsiteUrl
          clientShortTestimonial
          clientFullTestimonial
          clientProjectOverview
        }
      }
    }
  }
`

// TODO: Replace "MemberStyles" with "ClientStyles" ??
const clientDetail = ({ data }) => {
  const {
    clientName,
    clientIndustry,
    clientWebsiteUrl,
    clientShortTestimonial,
    clientFullTestimonial,
    clientProjectOverview,
    services
  } = data.wordPress.client.clientsFieldGroup

  return (
    <>
      <SEO
        article='true'
        description={clientShortTestimonial}
        title={clientName} />
      <div className={MemberStyles.memberContainer}>
        <div>
          <div>
            <section>
              <button
                onClick={goBack}
                type='button'
              >
                {formatHTMLEntity('Back', '2190', true)}
              </button>
            </section>
            <div className={MemberStyles.titleContainer}>
              <h1>{clientName}</h1>
            </div>
            <div>
              <div>
                <div>
                  <p>Client Website</p>
                  <p>
                    <a
                      href={clientWebsiteUrl}
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      {clientWebsiteUrl}
                    </a>
                  </p>
                </div>
                <div>
                  <p>Industry</p>
                  <p>{clientIndustry}</p>
                </div>
              </div>
              <div>
                <div>
                  <p>
                    Services Rendered
                  </p>
                  <p>
                    {isNotEmptyArray(services) &&
                      services.map((item, index) => (
                        <span key={index.toString()}>
                          {item.service &&
                            item.service.servicesFieldGroup.serviceTitle}
                        </span>
                      ))}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={ClientStyles.clientDescription}>
            <p className={ClientStyles.clientShortTestimonial}>
              {clientShortTestimonial}
            </p>
          </div>
          <div
            className={ClientStyles.clientDescription}
            dangerouslySetInnerHTML={{ __html: clientProjectOverview }}
          />
          <div className={ClientStyles.clientDescription}>
            <div
              className={ClientStyles.clientDescription}
              dangerouslySetInnerHTML={{ __html: clientFullTestimonial }}
            />
          </div>
          <span onClick={goBack}>
            {formatHTMLEntity('Back', '276E', true)}
          </span>
        </div>
      </div>
    </>
  )
}
clientDetail.propTypes = {
  data: PropTypes.object.isRequired
}
export default clientDetail
