// extracted by mini-css-extract-plugin
export var verticalBlogInner = "member-module--verticalBlogInner--q4vKu";
export var wrapper = "member-module--wrapper--Nw3bz";
export var memberContainer = "member-module--memberContainer--ro2od";
export var memberCityCountry = "member-module--memberCityCountry--8wd-C";
export var memberImage = "member-module--memberImage--Nvhc4";
export var socialMedia = "member-module--socialMedia--tDPld";
export var socialMediaLinksContainer = "member-module--socialMediaLinksContainer--XgGdG";
export var titleContainer = "member-module--titleContainer--N9XFx";
export var detailsFlexContainer = "member-module--detailsFlexContainer--siBYo";
export var detailsFlexContainerInner = "member-module--detailsFlexContainerInner--Qc6H3";
export var socialContainer = "member-module--socialContainer--7u8Bw";
export var shareContainer = "member-module--shareContainer---W1hB";
export var socialLinks = "member-module--socialLinks--psadO";
export var detailsFlexContainerInnerTwo = "member-module--detailsFlexContainerInnerTwo--of8XP";
export var leftCardContainer = "member-module--leftCardContainer--wWTe2";
export var jobTitle = "member-module--jobTitle--GJYan";
export var blogSection = "member-module--blogSection--Brq6e";
export var modalContainer = "member-module--modalContainer--frIrl";
export var modalContainerInner = "member-module--modalContainerInner--3eNMX";
export var memberDescription = "member-module--memberDescription--U0U9u";
export var memberDescriptionDesktop = "member-module--memberDescriptionDesktop--0mcSn";
export var certificationMain = "member-module--certificationMain--4s-mx";
export var skillsSection = "member-module--skillsSection--Gd7BI";
export var affliationSection = "member-module--affliationSection--a5uNx";
export var skillsSectionInner = "member-module--skillsSectionInner--aqV45";
export var accordion = "member-module--accordion--PgV4S";
export var certificationSection = "member-module--certificationSection--I1X8I";
export var skillsSectionMobile = "member-module--skillsSectionMobile--jp-L4";
export var memberDescriptionMobile = "member-module--memberDescriptionMobile--k7ypc";
export var skillsSectionDesktop = "member-module--skillsSectionDesktop--5dORf";